import React from 'react'
import '../../styles/loading.scss'

const Loading = () => (
  <div className="gooey">
    <span className="dot"></span>
    <div className="dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
)

export default Loading
