import React from 'react'
import { useSelector } from 'react-redux'

import Banner from './Banner'
import WhyChoosePtm from './WhyChoosePtm'
import PlanYourNextTrip from './PlanYourNextTrip'

import '../../styles/home.scss'

const Home = () => {
  const { home } = useSelector(state => state.site.data)

  return (
    <div data-page="Home">
      <Banner images={home.attributes.Banners.data} />
      <WhyChoosePtm data={home.attributes.WhyPTM} />
      <PlanYourNextTrip data={home.attributes.PlanYourNextTrip} />
    </div>
  )
}

export default Home
