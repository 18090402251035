import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

import Banner from '../home/Banner'
import Markdown from '../../components/MarkDown'
import NotFound from './NotFound'

import { SERVER_URL } from '../../utils/constants'

import '../../styles/service.scss'

const isSameUrl = (a, b) => {
  return a.replace(/^\//, '') === b.replace(/^\//, '')
}

const Service = () => {
  const location = useLocation()
  const { services } = useSelector(state => state.site.data)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const service = services.find(service => isSameUrl(service.attributes.Url, location.pathname))

  if (!service) return <NotFound />

  return (
    <div data-page="Service">
      <Banner images={service.attributes.Banners.data} />
      <Container className="content">
        <Row>
          <Col xs={12}>
            <h2>{service.attributes.Title}</h2>
            <Markdown>{service.attributes.Content}</Markdown>
          </Col>
        </Row>

        <Row className="services">
          {service.attributes.SubServices.map(sub => {
            const image = sub.Image.data.attributes

            return (
              <Col key={sub.id} md={3} xs={6}>
                <img src={`${SERVER_URL}${image.url}`} alt={image.name} />
                <p>{sub.Title}</p>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Service
