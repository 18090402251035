const labels = {
  en: {
    home: 'Home',
    our_services: 'Our Services',
    about_us: 'About Us',
    contact: 'Contact',
    contact_us: 'Contact Us',
    address: 'Address',
    our_social: 'Our Social Media',
    dont_miss_deal: "Don't miss any deal!",
    subscribe_and_save: 'Subscribe & Save on your travel.',
    subscribe: 'Subscribe',
    all_right_reserved: 'All Right Reserved',
    travel_agency: 'Travel Agency',
    already_subscribe: 'You\'ve already subsribed to PTM Announcement',
    success_subscribe: 'You\'ve successfully subsribed to PTM Announcement',
    success_inquiry: 'Your inquiry is successfully submitted',
    fail_inquiry: 'Your inquiry is failed submitted. Please try again.',

    contact_name: 'Name',
    contact_phone: 'Phone Number',
    contact_email: 'Email Address',
    contact_subject: 'Subject',
    contact_message: 'Message',
    contact_submit: 'Submit'
  },
  km: {
    home: 'ទំព័រដើម',
    our_services: 'សេវាកម្មរបស់ពួកយើង',
    about_us: 'អំពីយើង',
    contact: 'ទំនាក់ទំនង',
    contact_us: 'ទំនាក់ទំនង',
    address: 'អាសយដ្ឋាន',
    our_social: 'បណ្តាញសង្គម',
    dont_miss_deal: "ដើម្បីកុំឲ្យខកខានឪកាសពិសេស",
    subscribe_and_save: 'ទទួលដំនឹងថ្មី',
    subscribe: 'ទទួលដំនឹង',
    all_right_reserved: 'រក្សាសិទ្ធគ្រប់យ៉ាង',
    travel_agency: 'ក្រុមហ៊ុនដំណើរកម្សាន្ត',
    already_subscribe: 'You\'ve already subsribed to PTM Announcement',
    success_subscribe: 'You\'ve successfully subsribed to PTM Announcement',
    success_inquiry: 'Your inquiry is successfully submitted',
    fail_inquiry: 'Your inquiry is failed submitted. Please try again.',

    contact_name: 'ឈ្មោះ',
    contact_phone: 'លេខទូរស័ព្ទ',
    contact_email: 'អាសយដ្ឋានអ៉ីម៉ែល',
    contact_subject: 'ប្រធានបទ',
    contact_message: 'បរិយាយ',
    contact_submit: 'បញ្ឌូន'
  }
}

const t = (locale = 'en', key) => {
  return labels[locale][key] || labels.en[key] || key
}

const i18n = { t }

export default i18n
