import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Markdown from '../../components/MarkDown'

import { SERVER_URL } from '../../utils/constants'

const WhyChoosePtm = ({ data }) => {
  const background = data.Background.data.attributes

  const onServiceClick = url => {
    if (!url) return

    if (url.search(/^http[s]?:\/\//) === -1) {
      url = 'https://' + url;
    }

    window.open(url)
  }

  return (
    <div className="plan-your-next-trip">
      <div className="background" style={{ backgroundImage: `url("${SERVER_URL}${background.url}")` }}>
        <Container>
          <h2>{data.Title}</h2>
          <Row>
            {data.Process.map(process => {
              const image = process.Icon.data.attributes

              return (
                <Col xs={12} sm={6} md={3} key={process.id} className="text-center">
                  <div
                    className={`process-icon-wrapper ${process.Url ? 'clickable' : ''}`}
                    onClick={() => onServiceClick(process.Url)}
                  >
                    <img src={`${SERVER_URL}${image.url}`} alt={image.name} />
                  </div>
                  <p>{process.Label}</p>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>

      <Container className="content">
        <Row>
          <Col xs={12} md={6} className="mb-3">
            <Row>
              {data.Images.data.map(image => (
                <Col xs={6} key={image.id}>
                  <img src={`${SERVER_URL}${image.attributes.url}`} alt={image.attributes.name} />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={12} md={6} className="d-flex">
            <h3>{data.SecondaryTitle}</h3>
            <Markdown>{data.Content}</Markdown>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyChoosePtm
