import React from 'react'

const NotFound = () => (
  <div className="not-found">
    <p className="code">404</p>
    <p className="text">Not Found</p>
  </div>
)

export default NotFound
