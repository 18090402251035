import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

import { BiPhoneCall } from 'react-icons/bi'
import { MdEmail } from 'react-icons/md'
import { FaFacebook, FaTelegram, FaLine } from 'react-icons/fa'

import i18n from '../utils/i18n'

const Footer = () => {
  const navigate = useNavigate()

  const { locale, data: { setting, services } } = useSelector(state => state.site)
  const [email, setEmail] = useState('')

  const { Information: information, SocialMedia: socialMedia } = setting.attributes

  const onServiceClick = service => {
    if (service.ExternalLink) {
      let url = service.Url

      if (url.search(/^http[s]?:\/\//) === -1) {
        url = 'https://' + url;
      }

      window.open(url)
    } else {
      navigate(service.Url)
    }
  }

  const subscribe = async e => {
    try {
      e.preventDefault()

      await axios.post(`/subscriptions`, { data: { Email: email } })
      toast.success(i18n.t(locale, 'success_subscribe'))
    } catch (e) {
      toast.error(i18n.t(locale, 'already_subscribe'))
    }
  }

  return (
    <div className="footer">
      <Container >
        <Row>
          <Col lg={4} sm={12} className="contact">
            <h4>{i18n.t(locale, 'contact_us')}</h4>
            <div className="underline" />

            <p>
              <BiPhoneCall />
              <a href={`tel:${information.Phone}`}>{information.Phone}</a>
            </p>
            <p>
              <MdEmail />
              <a href={`mailto:${information.Email}`}>{information.Email}</a>
            </p>

            <h4 className="mt-5">{i18n.t(locale, 'address')}</h4>
            <div className="underline" />

            <p>{information.Address}</p>

          </Col>

          <Col lg={4} sm={12} className="services">
            <h4>{i18n.t(locale, 'our_services')}</h4>
            <div className="underline" />

            <Row>
              {services.map(service => (
                <Col key={service.id} xs={6}>
                  <div onClick={() => onServiceClick(service.attributes)} className="clickable" >
                    {service.attributes.Title}
                  </div>
                </Col>
              ))}
            </Row>
          </Col>

          <Col lg={4} sm={12} className="social-media">
            <h4>{i18n.t(locale, 'our_social')}</h4>
            <div className="underline" />

            <div className="social-media-wrapper">
              {socialMedia.Line && <FaLine size={30} className="clickable" onClick={() => window.open(socialMedia.Line)} />}
              {socialMedia.Telegram && <FaTelegram size={30} className="clickable" onClick={() => window.open(socialMedia.Telegram)} />}
              {socialMedia.Facebook && <FaFacebook size={30} className="clickable" onClick={() => window.open(socialMedia.Facebook)} />}
              {information.Email && <MdEmail size={30} className="clickable" onClick={() => window.open(`mailto:${information.Email}`) } />}
            </div>

            <p>{i18n.t(locale, 'dont_miss_deal')}</p>
            <p>{i18n.t(locale, 'subscribe_and_save')}</p>
            <form onSubmit={subscribe}>
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
              <Button type="submit">{i18n.t(locale, 'subscribe')}</Button>
            </form>
          </Col>
        </Row>
      </Container>

      <p className="all-right-reserved">{information.Name} &copy;. {i18n.t(locale, 'all_right_reserved')} 1994 - {new Date().getFullYear()}.</p>

    </div>
  )
}

export default Footer
