/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { createCustomEqual } from 'fast-equals'

const Marker = (options) => {
  const [marker, setMarker] = useState()

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  return null
}

const GoogleMap = ({ location, height }) => (
  <div style={{ display: 'flex', height: '100%', position: 'relative' }}>
    <Wrapper apiKey="AIzaSyBwPfQ9QX2b3JLQoomU7TtBy-kUDXi6cTw">
      <Map
        center={location}
        zoom={15}
        style={{ flexGrow: '1', height: height || 400 }}
      >
        <Marker position={location} />
      </Map>
    </Wrapper>
  </div>
)

const Map = ({ children, style, ...options }) => {
  const mapRef = useRef(null)
  const [map, setMap] = useState()

  useEffect(() => {
    if (mapRef.current && !map) {
      setMap(new window.google.maps.Map(mapRef.current, {}))
    }
  }, [mapRef, map])

  useDeepCompareEffectForMaps(() => {
    if (map) map.setOptions(options)
  }, [map, options])

  return (
    <>
      <div ref={mapRef} style={style} />

      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map })
        }

        return null
      })}
    </>
  )
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => deepEqual(a, b))

function useDeepCompareMemoize(value) {
  const ref = React.useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

export default GoogleMap
