import axios from 'axios'
import qs from 'qs'
import { SITE_TYPES } from '../types'

const setSite = data => ({
  type: SITE_TYPES.SET_DATA,
  data,
})

const setLocale = locale => ({
  type: SITE_TYPES.SET_LOCALE,
  locale,
})

const setLoading = val => ({
  type: SITE_TYPES.SET_LOADING,
  val
})

const getLocale = async () => {
  const response = await axios.get(`/i18n/locales`)
  return response.data
}

const getHome = async locale => {
  const query = qs.stringify({
    locale,
    populate: [
      'WhyPTM',
      'WhyPTM.Reasons',
      'WhyPTM.Reasons.Image',
      'PlanYourNextTrip',
      'Banners',
      'PlanYourNextTrip.Background',
      'PlanYourNextTrip.Process',
      'PlanYourNextTrip.Process.Icon',
      'PlanYourNextTrip.Images'
    ]
  })

  const response = await axios.get(`/home?${query}`)
  return response.data.data
}

const getAboutUs = async locale => {
  const query = qs.stringify({
    locale,
    populate: ['Banner', 'Images']
  })

  const response = await axios.get(`/about-us?${query}`)
  return response.data.data
}

const getOurServices = async locale => {
  const query = qs.stringify({
    locale,
    populate: ['Banner', 'Services', 'Services.Image']
  })

  const response = await axios.get(`/our-service?${query}`)
  return response.data.data
}

const getServices = async locale => {
  const query = qs.stringify({
    locale,
    populate: ['Banners', 'Icon', 'SubServices', 'SubServices.Image'],
    sort: ['id']
  })

  const response = await axios.get(`/services?${query}`)
  return response.data.data
}

const getSetting = async locale => {
  const query = qs.stringify({
    locale,
    populate: ['Information', 'SocialMedia', 'Information.Logo']
  })

  const response = await axios.get(`/site-setting?${query}`)
  return response.data.data
}

const getContactUs = async () => {
  const query = qs.stringify({
    populate: ['Banner']
  })

  const response = await axios.get(`/contact?${query}`)
  return response.data.data
}

const getSite = () => async (dispatch, getState) => {
  dispatch(setLoading(true))

  try {
    const { locale } = getState().site

    const [locales, setting, home, aboutUs, ourServices, services, contactUs] = await Promise.all([
      getLocale(),
      getSetting(locale),
      getHome(locale),
      getAboutUs(locale),
      getOurServices(locale),
      getServices(locale),
      getContactUs(),
    ])

    dispatch(setSite({
      ourServices,
      contactUs,
      services,
      locales,
      setting,
      aboutUs,
      home
    }))
  } catch (e) {
    dispatch(setSite({}))
  }
}

const actions = {
  getSite,
  setLocale,
  setLoading,
}

export default actions
