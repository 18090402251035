import React from 'react'

const serializeContent = content => {
  const rows = content.split('\n')

  let addUlTag = false

  const rowsWithHtmlTag = rows.map((row, index) => {
    if (row.startsWith('-')) {
      if (!addUlTag) {
        addUlTag = true
        return `<ul><li><span>${row.replace(/-\s*/, '')}</span></li>`
      } else {
        if (index === rows.length - 1) {
          return `<li><span>${row.replace(/-\s*/, '')}</span></li></ul>`
        }
      }

      return `<li><span>${row.replace(/-\s*/, '')}</span></li>`
    } else {
      if (addUlTag) {
        addUlTag = false

        return `</ul>\n${row.replace(/-\s*/, '')}`
      }

      return `${row}\n`
    }
  })

  return `<md-block rendered="content">${rowsWithHtmlTag.join('')}</md-block>`
}

const Markdown = ({ children }) => (
  <p
    className="break-line"
    dangerouslySetInnerHTML={{ __html: serializeContent(children) }}
  />
)

export default Markdown
