import React from 'react'
import { Carousel } from 'react-bootstrap'

import { SERVER_URL } from '../../utils/constants'

const Banner = ({ images }) => (
  <Carousel
    interval={5000}
    className="banner"
    controls={images.length > 1}
    indicators={images.length > 1}
  >
    {images.map(image => (
      <Carousel.Item key={image.id}>
        <img
          src={`${SERVER_URL}${image.attributes.url}`}
          className="d-block w-100"
          alt={image.attributes.name}
        />
      </Carousel.Item>
    ))}

  </Carousel>
)

export default Banner
