import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

import Banner from '../home/Banner'
import Markdown from '../../components/MarkDown'

import { SERVER_URL } from '../../utils/constants'

import '../../styles/our-services.scss'

const OurServices = () => {
  const navigate = useNavigate()
  const { ourServices, services } = useSelector(state => state.site.data)

  const onServiceClick = service => {
    if (service.ExternalLink) {
      let url = service.Url

      if (url.search(/^http[s]?:\/\//) === -1) {
        url = 'https://' + url;
      }

      window.open(url)
    } else {
      navigate(service.Url.startsWith('/') ? service.Url : `/${service.Url}`)
    }
  }

  return (
    <div data-page="Our-Services">
      <Banner images={[ourServices.attributes.Banner.data]} />
      <Container className="content">
        <Row>
          <Col xs={12}>
            <h2>{ourServices.attributes.Title}</h2>
            <Markdown>{ourServices.attributes.Content}</Markdown>
          </Col>
        </Row>

        <Row className="services">
          {services.map(({ id, attributes: service }) => {
            const image = service.Icon.data.attributes

            return (
              <Col key={id} md={3} xs={6}>
                <div className="service clickable" onClick={() => onServiceClick(service)}>
                  <img src={`${SERVER_URL}${image.url}`} alt={image.name} />
                  <span>{service.Title}</span>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default OurServices
