import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

import i18n from '../../utils/i18n'

import Banner from '../home/Banner'
import Map from '../../components/Map'

import '../../styles/contact.scss'

const Contact = () => {
  const { locale, data: { contactUs, setting } } = useSelector(state => state.site)

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = async e => {
    try {
      e.preventDefault()

      await axios.post(`/contact-uses`, { data: {
        Name: name,
        Phone: phone,
        Email: email,
        Subject: subject,
        Message: message
      }})

      toast.success(i18n.t(locale, 'success_inquiry'))

      setName('')
      setPhone('')
      setEmail('')
      setSubject('')
      setMessage('')

    } catch (err) {
      toast.error(i18n.t(locale, 'fail_inquiry'))
    }
  }

  return (
    <div data-page="Contact">
      <Banner images={[contactUs.attributes.Banner.data]} />

        <Container className="content">
          <h2 className="text-center">{i18n.t(locale, 'contact_us')}</h2>

          <Row>
            <Col sm={12} lg={6}>
              <Form onSubmit={onSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>{i18n.t(locale, 'contact_name')}</Form.Label>
                  <Form.Control required value={name} onChange={e => setName(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{i18n.t(locale, 'contact_email')}</Form.Label>
                  <Form.Control required type="email" value={email} onChange={e => setEmail(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{i18n.t(locale, 'contact_phone')}</Form.Label>
                  <Form.Control required value={phone} onChange={e => setPhone(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{i18n.t(locale, 'contact_subject')}</Form.Label>
                  <Form.Control required value={subject} onChange={e => setSubject(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{i18n.t(locale, 'contact_message')}</Form.Label>
                  <Form.Control required as="textarea" rows={5} value={message} onChange={e => setMessage(e.target.value)} />
                </Form.Group>

                <div className='text-end'>
                  <Button variant="primary" type="submit" className="ms-auto">
                    {i18n.t(locale, 'contact_submit')}
                  </Button>
                </div>
              </Form>
            </Col>
            <Col sm={12} lg={6}>
              <div className="map-wrapper">
                <Map
                  location={{
                    lat: Number(setting.attributes.Location.split(',')[0].trim()),
                    lng: Number(setting.attributes.Location.split(',')[1].trim()),
                  }}
                  height="100%"
                />
              </div>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default Contact
