import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'

import { API_BASE_URL } from './utils/constants'
import SiteActions from './redux/actions/site'

import Layout from './layout'
import Loading from './containers/loading'

import Home from './containers/home'
import Contact from './containers/contact'
import AboutUs from './containers/aboutUs'
import OurServices from './containers/our-services'
import Service from './containers/service'

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const dispatch = useDispatch()
  const { loading, data } = useSelector(state => state.site)
  const [locale, setLocale] = useState('en')

  useEffect(() => {
    axios.defaults.baseURL = API_BASE_URL
    const defaultLocale = window.localStorage.getItem('locale') || 'en'

    setLocale(defaultLocale)
    dispatch(SiteActions.setLocale(defaultLocale))
    dispatch(SiteActions.getSite())
  }, [dispatch])

  if (Object.keys(data).length === 0 || loading) {
    return <Loading />
  }

  return (
    <Layout locale={locale}>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="*" element={<Service />} />
      </Routes>

      <ToastContainer />
    </Layout>
  )
}

export default App