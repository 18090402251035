import React from 'react'

import TopHeader from './TopHeader'
import Navbar from './Navbar'
import Footer from './Footer'

const Layout = ({ locale, children }) => {
  return (
    <div data-locale={locale} className="layout">
      <TopHeader />
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
