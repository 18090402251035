import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Container, Navbar, Nav } from 'react-bootstrap'

import { SERVER_URL } from '../utils/constants'
import i18n from '../utils/i18n'

const CustomNavbar = () => {
  const navigate = useNavigate()

  const { locale, data: { setting } } = useSelector(state => state.site)
  const nav = window.location.pathname.split('/')[1] || '/'
  const logo = `${SERVER_URL}${setting.attributes.Information.Logo.data.attributes.url}`


  const MENUS = [
    {
      title: i18n.t(locale, 'home'),
      value: '/',
    },
    {
      title: i18n.t(locale, 'our_services'),
      value: 'our-services',
    },
    {
      title: i18n.t(locale, 'about_us'),
      value: 'about-us',
    },
    {
      title: i18n.t(locale, 'contact'),
      value: 'contact',
    },
  ]


  return (
    <Navbar collapseOnSelect expand="lg" className="top-nav">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {MENUS.map(menu => (
              <Nav.Link
                onClick={() => navigate(menu.value)}
                active={nav === menu.value}
                key={menu.value}
              >
                {menu.title}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default CustomNavbar