import Immutable from 'seamless-immutable'
import { SITE_TYPES } from '../types'

const initialState = Immutable({
  locale: 'en',
  data: {},
  loading: false,
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SITE_TYPES.SET_DATA:
      return state
        .set('loading', false)
        .set('data', action.data)

    case SITE_TYPES.SET_LOADING:
      return state
        .set('loading', action.val)

    case SITE_TYPES.SET_LOCALE:
      return state
        .set('locale', action.locale)

    default:
      return state
  }
}

export default reducer
