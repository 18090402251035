import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { SERVER_URL } from '../../utils/constants'

const WhyChoosePtm = ({ data }) => (
  <Container className="why-choose-ptm">
    <h2>{data.Title}</h2>
    <Row>
      <Col xs={12} md={8} className="mx-auto">
        <p>{data.Description}</p>
      </Col>
    </Row>

    <Row>
      {data.Reasons.map(reason => {
        const image = reason.Image.data.attributes

        return (
          <Col key={reason.id} xs={12} sm={6} md={4}>
            <div className="reason">
              <img src={`${SERVER_URL}${image.url}`} alt={image.name} />
              <p className="title">{reason.Label}</p>
              <p className="description">{reason.Description}</p>
            </div>
          </Col>
        )
      })}
    </Row>
  </Container>
)

export default WhyChoosePtm
