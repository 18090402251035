import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap'

import { BiPhoneCall } from 'react-icons/bi'
import { MdEmail } from 'react-icons/md'
import { FaFlag } from 'react-icons/fa'

const TopHeader = () => {
  const { locale, data: { locales, setting } } = useSelector(state => state.site)

  const onLocaleChange = newLocale => {
    window.localStorage.setItem('locale', newLocale.code)
    window.location.reload()
  }

  return (
    <div className="top-header">
      <Container>
        <Row>
          <Col xs={10} sm={10} md={8}>
            <div className="contact-container">
              <div className="contact">
                <BiPhoneCall /> <span><a href={`tel:${setting.attributes.Information.Phone}`}>{setting.attributes.Information.Phone}</a></span>
              </div>
              <div className="contact">
                <MdEmail /> <span><a href={`mailto:${setting.attributes.Information.Email}`}>{setting.attributes.Information.Email}</a></span>
              </div>
            </div>
          </Col>

          <Col xs={2} sm={2} md={4} className="d-flex justify-content-center justify-content-sm-center justify-content-md-end">
            <DropdownButton
              align="end"
              title={<span><FaFlag style={{ marginRight: 5 }} /> {locale.toUpperCase().replace('KM', 'KH')}</span>}
              id="dropdown-menu-align-end"
            >
              {locales.map(locale => (
                <Dropdown.Item key={locale.id} eventKey={locale.id} onClick={() => onLocaleChange(locale)}>
                  {locale.name.replace('km', 'KH').replace('en', 'EN')}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TopHeader
