import React from 'react'
import { useSelector } from 'react-redux'

import Banner from '../home/Banner'
import WhyPtm from './WhyPtm'

import '../../styles/about-us.scss'

const AboutUs = () => {
  const { aboutUs } = useSelector(state => state.site.data)

  return (
    <div data-page="About-Us">
      <Banner images={[aboutUs.attributes.Banner.data]} />
      <WhyPtm data={aboutUs.attributes} />
    </div>
  )
}

export default AboutUs
