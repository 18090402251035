import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Markdown from '../../components/MarkDown'

import { SERVER_URL } from '../../utils/constants'

const WhyPtm = ({ data }) => (
  <Container className="content">
    <Row>
      <Col xs={12}>
        <h2>{data.Title}</h2>
      </Col>
      <Col xs={12} md={6}>
        <Markdown>{data.Content}</Markdown>
      </Col>
      <Col xs={12} md={6} className="mb-3">
        <Row>
          {data.Images.data.map(image => (
            <Col xs={6} key={image.id}>
              <img src={`${SERVER_URL}${image.attributes.url}`} alt={image.attributes.name} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  </Container>
)

export default WhyPtm
